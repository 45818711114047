import React from "react"
import { Link, 
  // useStaticQuery, graphql 
} from "gatsby"
import Navigation from "../components/navigation"
import 'prismjs/themes/prism-okaidia.css';

export default ({ children }) => {
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //         }
  //       }
  //     }
  //   `
  // )
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <Link to="/">
            <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              viewBox="0 0 162.7 24.7" className="logo" >
              <g>
                <path class="st0" d="M56.7,21.5l-0.1-0.1L40,4.5l-8.9,8.8l8.5,8.2h-18l8.3-8.2l-9.2-8.8L3.8,21.4H2.5L20.7,3.3l9.8,9.4L40,3.3
		l17,17.4h102.1l0,0.8 M23.7,20.7h13.7l-7-6.7L23.7,20.7z"/>
              </g>
              <rect x="29.5" y="12.3" class="st0" width="2.2" height="2.2" />
            </svg>
          </Link>
        </div>
        <Navigation />
      </header>
      {children}
      <footer className="site-footer">
        <p>&copy; {new Date().getFullYear()} Casa Museo Tre Tetti &bull; <a href="https://www.museotretetti.it/home/" rel="noreferrer" target="_blank">www.museotretetti.it</a></p>
        <p className="post-title">Contattaci tramite <a href="mailto:giorgio.riva@tretetti.it">email &rarr;</a>
        </p>

      </footer>
    </div>
  )
}
